/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=DM+Sans&family=Inter&display=swap');


* {
  font-size: 16px;
  font-family: 'DM Sans', sans-serif;
  color: var(--black);
  box-sizing: border-box !important;
}

.cursor-pointer {
  cursor: pointer;
}

.z-index-10 {
  z-index: 10;
}

a {
  text-decoration: none;
}

.w-fit-content {
  width: fit-content;
}

.vertical-rule {
  height: 35%;
  width: 1px;
  background-color: #1981F7;
  margin: auto 0;
  border-radius: .375rem;
}

.object-fit-contain {
  object-fit: contain;
}

.hidden {
  visibility: hidden;
}
.img-thumbnail {
  border-color: transparent !important;
  background-color: transparent !important;
  padding: 0;
}

// arrows in input number
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

/* lesson table tooltip */

.lessons-list li:nth-child(2n) {
  background-color: var(--table-row-bg)!important;
}

.lesson-table-tooltip {
  display: inline-block;
  margin-left: 0.5em;
  position: relative;
}

.lesson-table-tooltip .lesson-table-tooltip-content {
  display: none;
  position: absolute;
  right: 36px;
  background-color: var(--bg-1);
  max-width: 300px;
  width: max-content;
  font-size: 0.8rem;
  color: rgba(0, 0, 0, 0.815);
  z-index: 1000;
  top: 0;
}

.lesson-table-tooltip:hover .lesson-table-tooltip-content {
  display: block;
}

// profile photo
.profile-photo {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.profile-photo:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}


/* lesson status */
.lesson-status {
  text-transform: uppercase;
  border-radius: .375rem;
  padding: .3rem .5rem;
}

.lesson-status-legent-wrapper {
  margin-right: -4px;
  overflow: hidden;
  margin-bottom: 10px;
  margin-top: -15px;
}

.lesson-status-legend .badge {
  margin: 4px;
  color: var(--white);
  font-weight: initial;
}

.badge.booked {
  background-color: #95d566;
}
.badge.due {
  background-color: #529f19;
}
.badge.in-progress {
  background-color: #84c3ff;
}

.badge.cancel {
  background-color: #dadada;
}

.badge.complete {
  background-color: #076fd0;
}

.badge.student-invalidate {
  background-color: #9e9e9e;
}

.badge.teacher-invalidate {
  background-color: #484848;
}

.badge-outline-secondary {
  border: 0.5px solid #A0A1B5;
  border-radius: .25rem;
  color: #A0A1B5;
}

.btn-nav {
  width: 2.5rem;
  height: 2.5rem;
  padding: 0;
  line-height: 1;
}
//navbar
.nav-item {
    display: flex;
    text-align: left;
    align-items: center;
    border-radius: .5rem;
    padding: .4rem 1rem;
    margin: .4rem 0;
    cursor: pointer;
    .nav-link {
        font-size: 0.8rem;
        padding-left: 0;
        text-transform: capitalize;
        color: var(--dark-gray);
    }
    app-icon {
        position: relative;
        right: .7rem;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 2.3rem;
        width: 2.3rem;
        border-radius: 50%;
    }
}

.nav-pills .nav-item a {
  background-color: var(--btn-pill-bg);
  color: var(--btn-bill-color);
  &.active {
    background-color: var(--primary);
    border-color: var(--primary);
    color: var(--white);
  }
}

.nav-tabs .nav-link {
  background-color: transparent !important;
  border-color: var(--primary) !important;
  color: var(--primary) !important;
  border-radius: 50rem !important;
  &.active {
    background-color: var(--primary) !important;
    border-color: var(--primary) !important;
    color: var(--white) !important;
  }
}

//backgrounds
.bg-gray {
  background-color: #E8EAEC;
}
.bg-workspace {
  background: radial-gradient(closest-side at 50% 50%,var(--bg-workspace-1) -30%,var(--bg-workspace-2) 100%) 0% 0% no-repeat padding-box;
}
.bg-warning {
  background-color: #F09825 !important;
}
.bg-success {
  background-color: #27A51C !important;
}
.bg-primary {
  background-color: var(--primary) !important;
}
.bg-secondary {
  background-color: var(--bg-secondary) !important;
}
.bg-watermark::before {
  content: "";
  background-image: url(./assets/img/Group71292x.png);
  background-position: center center;
  background-attachment: cover;
  background-repeat: repeat;
  background-size: 70%;
  mix-blend-mode: color-burn;
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  opacity: .4;
}

//borders
.border {
  border-color: var(--border-color-2) !important;
}
.border-secondary {
  border-color: var(--gray) !important;
}
.border-white {
  border-color: #FFFFFF !important;
}
.border-primary {
  border-color: var(--primary) !important;
}

//fonts
 //font color
.text-muted {
  color: var(--dark-gray) !important;
}
.text-warning {
  color: #F09825 !important;
}
.text-danger {
  color: #FF4032 !important;
}
.text-success {
  color: #27A51C !important;
}
.text-black {
  color: var(--black) !important;
}
 //font size
.xsmall, .xsmall * { //description
  font-size: .75rem !important;
}
.small, .small * {
  font-size: .875rem !important;
}
.lead, .lead * {
  font-size: 1.25rem;
  font-weight: 300;
}

//buttons
.btn-primary {
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
  color: #FFFFFF;

  &:hover {
    background-color: var(--primary-hover) !important;
    border-color: var(--primary-hover) !important;
  }
}

.btn-secondary {
  background-color: var(--secondary) !important;
  border-color: var(--secondary) !important;
  color: #FFFFFF;

  &:hover {
    background-color: var(--secondary-hover) !important;
    border-color: var(--secondary-hover) !important;
  }
}

.btn-outline-secondary {
  // background-color: var(--white);
  border-color: var(--secondary) !important;

  &:hover, &:focus, &:active, &:focus-visible {
    background-color: var(--secondary) !important;
    border-color: var(--secondary) !important;
  }
}

.btn-outline-secondary-2 {
  background-color: var(--card-bg);
  border-color: var(--table-row-bg) !important;
  color: var(--black);

  &:hover, &:focus, &:active, &:focus-visible {
    background-color: var(--table-row-bg) !important;
    border-color: var(--table-row-bg) !important;
  }
}

.btn.show {
  background-color: inherit;
}

.btn-sm {
  font-size: .8rem !important;
}

//icons

.icon-bg {
  position: relative;
  right: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.3rem;
  width: 2.3rem;
  min-width: 2.3rem;
  border-radius: 50%;
}

//icon color
.icon-primary path {
  fill: #1981F7;
}
.icon-secondary {
  fill: var(--dark-gray);
}
.icon-black {
  fill: var(--black);
}
.icon-white, .icon-white path {
  fill: var(--white);
}
.icon-danger {
  fill: #dc3545;
}
.icon-success {
  fill: #27A51C;
}

//icon size
.icon-lg svg{
  scale: 1.8;
}
.icon-md svg{
  scale: 1.5;
}
.icon-sm svg{
  scale: .9;
}
.icon-xs svg{
  scale: .75;
}

.scale-icon-up-hover:hover svg {
  scale: 1.2 !important;
}

//card
.card {
  padding: 1.2rem 0;
  background-color: var(--card-bg);
  border-color: transparent;
  border-bottom: .5rem solid var(--card-border-color);
  border-radius: .5rem;
}
.card-header, .card-body {
  padding-inline: 2.2rem;
}

.card-header {
  display: flex;
  background-color: transparent;
  border: none;
}

.card-footer {
   background-color: transparent;
   border: none;
   padding-inline: 1.2rem;
}

.card-border {
  border: 2px solid var(--gray);
  border-bottom: .5rem solid var(--gray);
}

//modal
.modal-content {
  background-color: var(--card-bg);
  &>* {
    padding: 1rem 1.5rem;
  }
  .modal-header, .modal-footer {
    border-color: var(--gray) !important;
  }
}
//list

.separated-list li{
  border-top: 1px solid var(--gray);
  padding: .25rem;
}
.separated-list li:first-child {
  border: none;
}

//table
.table * {
  font-size: .9rem;
}

.table-sm * {
  font-size: .8rem;
}

.table-hover tbody {
  cursor: pointer;
}

thead, tbody, tfoot, tr, td {
  border-color: transparent!important;
  border-style: none!important;
  border-width: 0;
  vertical-align: middle;
}

.table-row {
  &>th, >td {
      padding-top: .8rem;
      padding-bottom: .8rem;
      &:first-child {
          border-bottom-left-radius: .5rem !important;
          border-top-left-radius: .5rem !important;
          padding-left: 1.7rem;
      }
      &:last-child {
          border-bottom-right-radius: .5rem !important;
          border-top-right-radius: .5rem !important;
          padding-right: 1.7rem;
      }
  }
}

.table-row-hover {
  &>td {
      cursor: pointer;
  }
}

.table-row-head {
  &>th {
    color: var(--dark-gray);
    background-color: var(--table-head-bg)!important;
    vertical-align: middle;
  }
}

.table-body {
  &>tr:nth-child(even) {
    &>td, >th{
      background-color: var(--table-row-bg);
    }
  }
}
//list group
.list-group .list-group-item {
  border: 0;
  border-radius: .5rem;
  background-color: transparent;
}
.list-group div:not(:nth-child(even)) .list-group-item,
.list-group .list-group-item:not(:nth-child(even)) {
  background-color: var(--light-gray);
}

//breadcrumb
.breadcrumb {
  --bs-breadcrumb-divider: '>';
  --bs-breadcrumb-divider-color: #A0A1B5;
  .breadcrumb-item, .breadcrumb-item>a{
    font-size: .95rem;
    color: var(--bg-secondary);
  }
  .active, .active>a {
    color: var(--dark-gray);
  }
}

/* inputs */
.form-control, .form-select, .form-control-group {
  background-color: var(--light-gray);
  border-color: var(--light-gray);
  color: var(--black);
  font-size: .75rem!important;
  &::placeholder {
    color: #A0A1B5;
  }
  &:hover {
    box-shadow: 0px 0px 10px var(--input-shadow);
  }
  &:focus {
    color: var(--black);
    box-shadow: 0px 0px 10px var(--input-shadow);
    background-color: var(--light-gray) !important;
    border: 1px solid var(--input-border);
  }
  &:disabled {
    background-color: var(--input-disabled-bg) !important;
    border-color: var(--input-disabled-bg) !important;
  }
}

.form-control-group {
  border: 1px solid transparent;
  &:focus-within {
    box-shadow: 0px 0px 10px var(--input-shadow);
    border: 1px solid var(--input-border);
    background-color: var(--light-gray);
  }
}

.form-select {
  cursor: pointer;
  background-image: url("./assets/img/expand_more_FILL0_wght400_GRAD0_opsz20.svg") !important;
}

.form-control-group .form-control {
  border: transparent;
  background: transparent;
  &:hover {
    box-shadow: unset;
  }
  &:focus {
    box-shadow: unset;
    border: none;
  }
}

.form-control:disabled {
  // background: var(--disabled) !important;
  pointer-events: none;
}

.form-floating label {
  color: var(--dark-gray);
  font-size: .75rem!important;
  padding: 1.2rem .75rem;
}

.form-floating textarea {
  min-height: 7rem;
}

.form-select {
  padding-left: .75rem;
  ~label {
    opacity: 1 !important;
    transform: unset !important;
  }
  &.select-not-empty ~ label {
  opacity: .65 !important;
    transform: scale(.85) translateY(-.5rem) translateX(.15rem)!important;
  }
}

.light-theme .form-check-input:disabled {
background-color: #00000024;;
}

input.success {
  background-color: #28a7453b !important;
}

.field-required::after {
  content: " *";
  color: rgb(180, 0, 0);
}

.gender-input {
  background: var(--light-gray);
  border: var(--light-gray);
  font-size: .75rem !important;
  border-radius: .5rem;
  padding: .75rem;
  .gender-header {
    font-size: calc(.85 * .75rem);
    opacity: .65;
    font-family: 'Inter', sans-serif;
    margin-bottom: .25rem;
  }
  &.gender-input-disabled {
    background-color: var(--input-disabled-bg);
    border-color: var(--input-disabled-bg);
  }
}

.form-check-input[type="radio"] {
  background-color: transparent;
  border: 2px solid var(--dark-gray);
  &:checked {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23000'/%3e%3c/svg%3e");
  }
}

.disabled {
  background-color: #A0A1B542 !important;
}

.linked {
  pointer-events: none;
  box-shadow: inset 0px 0px 0px 2.5px var(--linked-border);
}

.unlinked {
  background-color: #A0A1B542 !important;
  border-color: #A0A1B542 !important;
}

//dropdown
.dropdown-menu {
  padding: .5rem;
  background-color: var(--dropdown-bg);
  border-radius: .5rem;

  .dropdown-item {
    color: var(--white);
    padding: .75rem;
    border-radius: .5rem;

    span {
      color: #fff;
      margin: auto 0;
    }

    app-icon {
      margin-right: .5rem;
      position: relative;
      top: -.1rem;
    }

    &:hover {
      background-color: var(--primary);
    }
  }
}

.td-date {
  min-width: 5rem;
}

//initials-circle-box
.initials-circle-box {
  display: flex;
  background-color: var(--secondary-hover);
  width: 3rem;
  min-width: 3rem;
  height: 3rem;
  border-radius: 50%;
}
.initials-circle-box-sm {
  display: flex;
  background-color: var(--secondary-hover);
  width: 2rem;
  min-width: 2rem;
  height: 2rem;
  border-radius: 50%;
}
.initials-circle-box-lg {
  display: flex;
  background-color: var(--secondary-hover);
  width: 4rem;
  min-width: 4rem;
  height: 4rem;
  border-radius: 50%;
}

//photo input
.form-file {
  width: 100%;
  border-radius: .5rem;
  padding: 1rem;

  .photo-square {
      width: 10rem;
      height: 10rem;
      border-radius: .5rem;
      border-style: dashed;
      border-color: var(--gray);
      border-width: 1px;
  }

  .photo-circle {
      width: 10rem;
      height: 10rem;
      border-radius:50%;
      border-style: dashed;
      border-color: var(--gray);
      border-width: 1px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      display: flex;
      justify-content: center;
      align-items: center;
  }

  .img-thumbnail {
      background-color: transparent;
  }
}

.skype-icon {
  position: absolute;
  right: 1.5rem;
  top: calc((100% - 26.4px) / 2);
  scale: 1.1;
}

.object-fit-cover {
  object-fit: cover;
}

.accordion-item {
  background-color: #214875 !important;
  border-radius: .5em !important;
  border: 0 !important;
  .accordion-button {
    color: inherit !important;
    box-shadow: none !important;
    background-color: #093D78 !important;
    border-radius: .5em !important;
    :focus {
      outline: none;
      color: inherit;
    }
  }
}

.accordion-body {
  .accordion-button {
    font-size: .9rem;
    background-color: #14345a !important;
  }
  .accordion-item {
    border: 0;
    background-color: #214875 !important;
    border-radius: .5em !important;
  }
}

.light-theme {
  .accordion-item {
    background-color: #f9f9f9 !important;
    .accordion-button {
      background-color: #dfeeff !important;
    }
  }
  .accordion-body {
    .accordion-button {
      background-color: #dfdfdf !important;
    }
    .accordion-item {
      background-color: #f9f9f9 !important;
    }
  }
}

@media only screen and (min-width: 576px) {
  .gender-input {
    height: calc(3.5rem + 2px);
  }
}
@media only screen and (min-width: 768px) {
  .border-md-start {
    border-left-width: 1px;
    border-left-style: solid;
  }
}
@media only screen and (min-width: 992px) {
  .border-lg-start {
    border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }
}
