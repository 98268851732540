body.light-theme {
  --black: #091421;
  --white: #FFFFFF;
  --bg-1: #FFFFFF;
  --light-gray: #F2F3F3;
  --gray: #EBEBEB;
  --dark-gray: #747596;
  --primary: #0679FC;
  --primary-hover: #0665D1;
  --secondary: #b0b1c5;
  --secondary-hover: #A0A1B5;
  --border-color: #EDEDED;
  --card-bg: #FFFFFF;
  --card-border-color: #A0A1B5;
  --bg-workspace-1: #F2F3F3;
  --bg-workspace-2: #F2F3F3;
  --table-head-bg: #f2f3f3;
  --table-row-bg: #f2f3f3;
  --input-shadow: #74759680;
  --input-border: #A0A1B5;
  --dropdown-bg: #747596;
  --border-color-2: #dee2e6;
  --bg-secondary: #a0a1b5;
  --lesson-card-bg: #f2f3f3;
  --calendar-card-bg: #f2f3f3c1;
  --btn-pill-bg: #FFFFFF;
  --btn-pill-color: #0679FC;
  --input-disabled-bg: #d4d7db;
  --nav-icons: #747596;
  --dropdown-menu-bg:#f5f5f5;
  --dropdown-item-bg:#FFFFFF;
  --linked-border: #29c01c;
}

body {
  --black: #e9ecef;
  --white: #FFFFFF;
  --bg-1: #091421;
  --light-gray: #093D78;
  --gray: #135BAD70;
  --dark-gray: #A0A1B5;
  --primary: #0679FC;
  --primary-hover: #0665D1;
  --secondary: #214875;
  --secondary-hover: #14345a;
  --border-color: #1C395A;
  --card-bg: #032B58;
  --card-border-color: #093D78;
  --bg-workspace-1: #0078FF;
  --bg-workspace-2: #0E1D2E;
  --table-head-bg: #093D78;
  --table-row-bg: #17477E;
  --input-shadow: #0F7EF880;
  --input-border: #2680EB;
  --dropdown-bg: #091421;
  --border-color-2: #10529c;
  --bg-secondary: #6B7389;
  --lesson-card-bg: #0E1D2E;
  --calendar-card-bg: #159cff3b;
  --btn-pill-bg: #1C395A;
  --btn-pill-color: #FFFFFF;
  --input-disabled-bg: #2E5075;
  --nav-icons: #0679fc;
  --dropdown-menu-bg:#091421;
  --dropdown-item-bg:#1C395A;
  --linked-border: #17760f;
}
